<template>
  <div id="page">
    <Header />
    <Footer />
    <PageTitle :title="'Ticket Details'" />
    <div class="page-title-clear"></div>
    <div class="page-content">
      <div
        class="card card-style"
        :style="{
          backgroundImage: `url(https://sbticket.kreya.my.id${ticketData.image})`,
        }"
        data-card-height="300"
      >
        <div class="card-top"></div>
        <div class="card-bottom mb-3 ms-3 me-3">
          <h1 class="color-white font-800 mb-0">{{ ticketData.title }}</h1>
          <p class="color-white font-14 mb-0 opacity-60"></p>
        </div>

        <div class="card-overlay bg-black opacity-60"></div>
      </div>

      <div class="card card-style">
        <div class="content">
          <p class="font-600 color-highlight mb-n1">All The Details</p>
          <h3>Description</h3>
          <p v-html="ticketData.description"></p>

          <router-link
            :to="{ name: 'ContentVideo' }"
            class="btn btn-s gradient-green color-white font-600 rounded-s"
          >
            Trailer Ticket</router-link
          >
          <div class="divider mt-3"></div>

          <p class="font-600 color-highlight mb-n1">Information</p>
          <h3></h3>
          <div class="row">
            <div class="col-7 color-theme font-600">Room</div>
            <div class="col-5">{{ ticketData.room }}</div>
            <div class="col-7 color-theme font-600">Price</div>
            <div class="col-5">{{ ticketData.price }}</div>
            <div class="col-7 color-theme font-600">Session</div>
            <div class="col-5">{{ ticketData.session }}</div>
          </div>

          <div class="divider mt-3"></div>

          <a
            @click="menuOpen('order-2')"
            data-menu="order-2"
            class="
              btn btn-full
              gradient-highlight
              font-13
              btn-m
              font-600
              mt-3
              rounded-s
            "
            >Buy</a
          >
        </div>
      </div>
      <!-- 
      <div class="splide double-slider slider-no-dots" id="double-slider-1">
        <div class="splide__track">
          <div class="splide__list">
            <div class="splide__slide">
              <div
                data-card-height="300"
                class="card mx-3 rounded-m shadow-l bg-13"
              >
                <div class="card-bottom text-center">
                  <h2 class="color-white font-700 font-15 mb-0">EazyMobile</h2>
                  <p class="text-center mb-3">
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                  </p>
                  <a
                    href="#"
                    class="
                      btn btn-s btn-full
                      font-13 font-600
                      gradient-highlight
                      rounded-s
                      me-2
                      ms-2
                      mb-2
                    "
                    >View</a
                  >
                </div>
                <div class="card-overlay bg-gradient"></div>
              </div>
            </div>
            <div class="splide__slide">
              <div
                data-card-height="300"
                class="card mx-3 rounded-m shadow-l bg-27"
              >
                <div class="card-bottom text-center">
                  <h2 class="color-white font-700 font-15 mb-0">UltraMobile</h2>
                  <p class="text-center mb-3">
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                  </p>
                  <a
                    href="#"
                    class="
                      btn btn-s btn-full
                      font-13 font-600
                      gradient-highlight
                      rounded-s
                      me-2
                      ms-2
                      mb-2
                    "
                    >View</a
                  >
                </div>
                <div class="card-overlay bg-gradient"></div>
              </div>
            </div>
            <div class="splide__slide">
              <div
                data-card-height="300"
                class="card mx-3 rounded-m shadow-l bg-17"
              >
                <div class="card-bottom text-center">
                  <h2 class="color-white font-700 font-15 mb-0">KolorMobile</h2>
                  <p class="text-center mb-3">
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                    <i class="fa fa-star color-yellow1-dark"></i>
                  </p>
                  <a
                    href="#"
                    class="
                      btn btn-s btn-full
                      font-13 font-600
                      gradient-highlight
                      rounded-s
                      me-2
                      ms-2
                      mb-2
                    "
                    >View</a
                  >
                </div>
                <div class="card-overlay bg-gradient"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
-->
      <div data-menu-load="menu-footer.html"></div>
    </div>
    <!-- Page content ends here-->

    <!-- Bahan hide slide bawah ke atas -->
    <div
      id="order-2"
      class="menu menu-box-bottom rounded-m"
      data-menu-height="425"
    >
      <div class="menu-title">
        <p class="color-highlight">What would you like?</p>
        <h1 class="font-22">Edit your Order</h1>
        <a @click="menuClose" class="close-menu"
          ><i class="fa fa-times-circle"></i
        ></a>
      </div>
      <div class="divider mb-0"></div>
      <div class="content mt-3">
        <div class="d-flex mb-3">
          <div class="align-self-center">
            <img
              :src="`https://sbticket.kreya.my.id${ticketData.image}`"
              class="rounded-sm me-3"
              width="60"
            />
          </div>
          <div class="align-self-center">
            <h2 class="font-15 line-height-s mt-1 mb-n1">
              {{ ticketData.title }}
            </h2>
            <p class="mb-0 font-11 mt-2">
              <i class="fa fa-money color-red-dark pe-1"></i>Rp.{{
                ticketData.price
              }}
              <i class="fa fa-door color-green-dark pe-1 ps-2"></i
              >{{ ticketData.room }}
              <i class="fa fa-clock color-green-dark pe-1 ps-2"></i
              >{{ ticketData.session }}
            </p>
          </div>
        </div>

        <div class="divider mb-2"></div>
        <div class="d-flex mb-3 pb-1">
          <div class="align-self-center">
            <h5 class="mb-0">Quantity</h5>
          </div>
          <div class="ms-auto align-self-center">
            <div class="stepper rounded-s small-switch me-n2">
              <a @click="reduce" class="stepper-sub"
                ><i class="fa fa-minus color-theme opacity-40"></i
              ></a>
              <input type="number" min="1" max="99" v-model="quantity" />
              <a @click="add" class="stepper-add"
                ><i class="fa fa-plus color-theme opacity-40"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="align-self-center">
            <h5 class="mb-0">Order Total</h5>
          </div>
          <div class="ms-auto align-self-center">
            <h5 class="mb-0">{{ priceTotal }}</h5>
          </div>
        </div>
        <div class="divider"></div>
        <a
          @click="pay"
          class="
            btn btn-full btn-m
            gradient-blue
            font-700
            rounded-sm
            close-menu
          "
          >Buy</a
        >
      </div>
    </div>
    <!-- Bahan -->
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import PageTitle from "@/components/PageTitle";
import { init_template, menuClose, menuOpen } from "@/appkit.js";
import { getTransactionToken, getTicket, doBuyTicket } from "../services";
export default {
  components: { Header, Footer, PageTitle },
  data() {
    return {
      ticketData: {},
      ticketCode: this.$route.params.slug,
      quantity: 1,
      priceTotal: 0,
    };
  },
  mounted() {
    init_template();
    this.getDetail();
  },
  methods: {
    menuClose,
    menuOpen,
    add() {
      this.quantity += 1;
      this.priceTotal = this.quantity * this.ticketData.price;
    },
    reduce() {
      if (this.quantity > 1) {
        this.quantity -= 1;
        this.priceTotal = this.quantity * this.ticketData.price;
      }
    },
    async getDetail() {
      getTicket(this.ticketCode)
        .then((succes) => {
          console.log(succes);
          this.ticketData = succes;
          this.priceTotal = this.ticketData.price;
        })
        .catch((error) => {
          console.log("error : ", error);
        });
    },
    async pay() {
      console.log("pay clicked");
      const formData = {
        ticket_code: this.ticketData.code,
        seat: this.quantity,
      };
      let transactionToken = {};
      getTransactionToken(formData)
        .then((succes) => {
          window.snap.pay(succes.transactionToken, {
            onSuccess: function (result) {
              /* You may add your own implementation here */
              alert("payment success!");
              console.log(result);
              doBuyTicket(result)
                .then((succes) => {
                  console.log("succes");
                })
                .catch((e) => {
                  console.log(e);
                });
            },
            onPending: function (result) {
              /* You may add your own implementation here */
              alert("wating your payment!");
              console.log(result);
              doBuyTicket(result)
                .then((succes) => {
                  console.log("succes");
                })
                .catch((e) => {
                  console.log(e);
                });
            },
            onError: function (result) {
              /* You may add your own implementation here */
              alert("payment failed!");
              console.log(result);
            },
            onClose: function () {
              /* You may add your own implementation here */
              alert("you closed the popup without finishing the payment");
            },
          });
        })
        .catch((error) => {
          console.log("error : ", error);
        });
    },
  },
};
</script>
